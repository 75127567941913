@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fafafa;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(206, 206, 206);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(146, 146, 146);
}

.react-pdf__Page__canvas {
  width: 100% !important;
}

/* Fix for poppers placed inside @wartek-id/dialog */
[data-popper-placement] {
  z-index: 101 !important;
}

/* Temporary fix for @wartek-id/file-upload component */
label[for='fileupload'] {
  display: inline !important;
}

.material-icons-outlined {
  font-family: 'Material Icons';
}
