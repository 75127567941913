.popoverContentContainer {
  width: 300px !important;
}

.popoverContentContainer > div {
  padding: 0 !important;
}

.navbarProfileMenu {
  padding: 0 !important;
}

.navbarProfileMenuBody {
  padding: 12px 16px !important;
}

.navbarProfileMenu span,
.navbarProfileMenu .navbarProfileMenuLink {
  display: flex;
  flex: 1;
}

.navbarProfileMenu .navbarProfileMenuLink {
  text-decoration: none;
}

.navbarProfileMenuLink {
  display: flex;
  flex: 1;
  padding: 12px 8px;
}
